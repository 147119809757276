import React from 'react';
import {NumberInputSpinner} from './NumberInputSpinner/NumberInputSpinner';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import s from './ProductOptions.scss';
import {Cell} from '../Layouts/Cell/Cell';
import {TextOption} from './OptionInputText/TextOption';
import {ProductPageSlotIds, UserInputType} from '../../constants';
import {isFunction} from 'util';
import {ProductType} from '@wix/wixstores-client-core/dist/es/src/types';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ProductOptionType} from '@wix/wixstores-graphql-schema/dist/es/src';
import {SubscriptionPlans} from './SubscriptionPlans/SubscriptionPlans';
import {IProductDTO} from '../../types/app-types';
import {ProductColors} from './ProductColors/ProductColors';
import {OptionsDropdown} from './OptionsDropdown/OptionsDropdown';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';

export interface ProductOptionsProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  shouldShowQuantity: boolean;
  shouldShowStockIndicator: boolean;
}

interface ProductOptionsState {
  addedToWishlist: boolean;
}

class ProductOptionsComponent extends React.Component<ProductOptionsProps, ProductOptionsState> {
  public state = {
    addedToWishlist: false,
  };

  public handleUserInput =
    (inputType: UserInputType, index: number) =>
    (data: string | number): void => {
      const {handleUserInput, validate} = this.props.globals;

      /* istanbul ignore else: todo: test */
      if (isFunction(handleUserInput)) {
        handleUserInput(inputType, data, index);
      }

      validate();
    };

  private renderOptions(): JSX.Element {
    const {product} = this.props.globals;

    return <>{product.options.map(this.renderOption)}</>;
  }

  private readonly renderOption = (option: IProductDTO['options'][number], index: number): JSX.Element => {
    if (option.optionType === ProductOptionType.COLOR) {
      return (
        <Cell className={s.colors} key={`product-options-${index}`}>
          <ProductColors optionIndex={index} />
        </Cell>
      );
    }

    return (
      <Cell key={`product-options-${index}`}>
        <OptionsDropdown optionIndex={index} />
      </Cell>
    );
  };

  private renderStockIndicator() {
    const {
      t,
      shouldShowStockIndicator,
      globals: {remainingItemCount},
    } = this.props;

    if (remainingItemCount > 0 && shouldShowStockIndicator) {
      return (
        <div className={s.stockIndicator} data-hook="stock-indicator">
          {t('productPage.lowStockMessage.label', {Inventory: remainingItemCount})}
        </div>
      );
    } else if (remainingItemCount === 0) {
      return (
        <div className={s.outOfStockIndicator} data-hook="out-of-stock-indicator">
          {t('PRODUCT_OUT_OF_STOCK_BUTTON')}
        </div>
      );
    }
    return null;
  }

  private renderQuantity() {
    const {
      t,
      globals: {product, quantityRange, userInputs},
    } = this.props;

    const shouldShowQuantity = this.props.shouldShowQuantity && product.productType === ProductType.PHYSICAL;
    const quantity = userInputs[UserInputType.Quantity][0];

    if (!shouldShowQuantity) {
      return null;
    }

    return (
      <Cell className={s.quantity}>
        <NumberInputSpinner
          max={quantityRange.max}
          value={quantity}
          title={t('QUANTITY_LABEL')}
          onChange={this.handleUserInput(UserInputType.Quantity, 0)}
        />
      </Cell>
    );
  }

  private renderCustomText() {
    const {
      globals: {
        product: {customTextFields},
      },
    } = this.props;

    return customTextFields.map((customTextField, index) => {
      return (
        <Cell key={index}>
          <TextOption
            errorIndex={index}
            title={customTextField.title}
            isRequired={customTextField.isMandatory}
            value=""
            maxLength={customTextField.inputLimit}
            handleOnChange={this.handleUserInput(UserInputType.Text, index)}
          />
        </Cell>
      );
    });
  }

  private renderSubscriptionPlans(): JSX.Element {
    return (
      <Cell>
        <SubscriptionPlans />
      </Cell>
    );
  }

  public render(): JSX.Element {
    const {
      globals: {
        product,
        isPreOrderState,
        product: {customTextFields},
        shouldShowSubscriptionPlans,
        shouldShorUserInputs,
        experiments: {renderProductPageSlots},
      },
    } = this.props;

    return (
      <div
        data-hook="product-options"
        className={s.productOptionsContainer}
        key={`product-option-${product.id}-${product.currency}`}>
        {shouldShorUserInputs && (
          <div data-hook="product-options-inputs">
            {this.renderOptions()}
            {renderProductPageSlots && (
              <div className={s.productPageSlot}>
                <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails5} />
              </div>
            )}
            {customTextFields && this.renderCustomText()}
            {renderProductPageSlots && (
              <div className={s.productPageSlot}>
                <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails6} />
              </div>
            )}
            {this.renderQuantity()}
            {renderProductPageSlots && (
              <div className={s.productPageSlot}>
                <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails7} />
              </div>
            )}
            {isPreOrderState ? null : this.renderStockIndicator()}
          </div>
        )}
        {shouldShowSubscriptionPlans && this.renderSubscriptionPlans()}
      </div>
    );
  }
}

export const ProductOptions: React.FC<ProductOptionsProps> = withGlobalProps(
  withTranslations('globals.texts')(ProductOptionsComponent)
);
