import React from 'react';
import _ from 'lodash';
import imagesLoaded from 'imagesloaded';
import style from './ProductGalleryLayout.scss';
import {MainMedia} from '../MainMedia/MainMedia';
import {ProductMediaNavigation} from '../ProductMediaNavigation/ProductMediaNavigation';
import {ImageMode} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {
  GalleryNavigationLocation,
  GalleryNavigationPosition,
  GalleryNavigationType,
  Layout,
  ProductPageSlotIds,
} from '../../../constants';
import {getMainImageRatio} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {DataHook as ThumbnailDataHook} from '../ProductMediaNavigation/MediaNavigationItem/Thumbnail/Thumbnail';
import md5 from 'md5';
import {LayoutConfig} from '../../../types/app-types';
import {ProductGalleryContext, ProductGalleryProvider} from '../ProductGalleryProvider/ProductGalleryProvider';
import {Mode, WishlistButton} from '../../ProductPageButtonsContainer/WishlistButton/WishlistButton';
import {ShowOnMobileOnly} from '@wix/wixstores-client-common-components/dist/es/src/HOC/responsive/ShowOnMobileOnly/ShowOnMobileOnly';
import {ModalGalleryLayout} from '../../ModalGallery/ModalGalleryLayout';
import cx from 'classnames';
import {ProductImageDataHook} from '../MainMedia/ProductImage/ProductImage';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';

export interface ProductGalleryLayoutProps extends ProvidedGlobalProps {
  layoutConfig: LayoutConfig;
  imageMode: ImageMode;
  imageRatioId: number;
  navigationType: GalleryNavigationType;
  navigationPosition: GalleryNavigationPosition;
  navigationLocation: GalleryNavigationLocation;
  layout: Layout;
}

class ProductGalleryLayoutComponent extends React.Component<ProductGalleryLayoutProps> {
  private imageDimensionFallback = {height: 1, width: 1};

  private isThumbnailsNavigationOfPositions(positions): boolean {
    const {navigationType, navigationPosition} = this.props;
    return (
      this.isWithThumbnails() &&
      navigationType === GalleryNavigationType.THUMBNAILS &&
      _.includes(positions, navigationPosition)
    );
  }

  private isHorizontalThumbnailsNavigation(): boolean {
    return this.isThumbnailsNavigationOfPositions([GalleryNavigationPosition.BOTTOM]);
  }

  private isVerticalThumbnailsNavigation(): boolean {
    return this.isThumbnailsNavigationOfPositions([GalleryNavigationPosition.LEFT, GalleryNavigationPosition.RIGHT]);
  }

  private getProductMediaNavigation(): JSX.Element {
    const {
      layoutConfig: {align, dimensions},
      navigationPosition,
      navigationLocation,
      navigationType,
      globals: {isMobile, product},
    } = this.props;
    const withDots = navigationType === GalleryNavigationType.DOTS;
    const conditionalClasses = {
      [style[`thumbnails-inside-${navigationPosition}-navigation`]]:
        !isMobile && navigationLocation === GalleryNavigationLocation.INSIDE,
    };
    const layoutDimensions = {...dimensions.thumbnails};
    if (navigationPosition === GalleryNavigationPosition.BOTTOM || isMobile) {
      layoutDimensions.heightConf = {num: 50, unit: 'px'};
    } else {
      layoutDimensions.heightConf = {...dimensions.mainMedia.heightConf};
      layoutDimensions.widthConf = {num: 50, unit: 'px'};
    }
    return (
      <div
        data-hook="thumbnails-container"
        className={cx([
          'slick-thumbnails-container-hook',
          style.thumbnails,
          style[`thumbnails-position-${navigationPosition}`],
          conditionalClasses,
        ])}>
        <ProductMediaNavigation
          media={product.media}
          vertical={this.isVerticalThumbnailsNavigation()}
          align={align}
          layoutDimensions={layoutDimensions}
          withDots={withDots}
        />
      </div>
    );
  }

  private getMainMediaContainerClass(): string {
    const {
      layoutConfig: {withMediaBorder},
      navigationPosition,
    } = this.props;
    const withVerticalThumbnailsNavigation = this.isVerticalThumbnailsNavigation();
    return cx([
      style.mainMediaContainer,
      style.contentBox,
      {
        [style[`main-media-${navigationPosition}-navigation`]]: withVerticalThumbnailsNavigation,
        [style.mainMediaBorder]: withMediaBorder,
      },
    ]);
  }

  private getRootClasses(withImageRatio: boolean): string {
    const {
      layoutConfig: {marginBottom},
    } = this.props;
    const horizontalNavigation = this.isHorizontalThumbnailsNavigation()
      ? [style.horizontalNavgationBottom, style[marginBottom]]
      : [];
    return cx([
      style.root,
      ...horizontalNavigation,
      {
        [style.rootAdaptive]: !withImageRatio,
      },
    ]);
  }

  public componentDidMount(): void {
    imagesLoaded(
      document.querySelectorAll(
        `[data-hook="${ThumbnailDataHook.image}"],[data-hook="${ThumbnailDataHook.video}"],[data-hook="${ProductImageDataHook.ProductImage}"]`
      ),
      () => {
        this.props.globals.updateLayout && this.props.globals.updateLayout();
      }
    );
    const {product} = this.props.globals;
    /* istanbul ignore next: todo: test */
    (product.media[0]?.height <= 1 || product.media[0]?.width <= 1) &&
      this.getImageDimensionFromUrl(product.media[0].fullUrl);
  }

  private isWithThumbnails(): boolean {
    const {
      globals: {hasMultipleMedia},
    } = this.props;
    return hasMultipleMedia;
  }

  private get signature() {
    return md5(this.props.globals.product.media.reduce((acc, m) => acc.concat(m.url), ''));
  }

  /* istanbul ignore next: todo: test */
  private getImageDimensionFromUrl(url): void {
    const img = new Image();
    img.onload = () => {
      this.imageDimensionFallback = {height: img.naturalHeight, width: img.naturalWidth};
    };
    img.src = url;
  }

  public render(): JSX.Element {
    const {
      layoutConfig: {withImageRatio, swipeToScroll, dimensions, withDynamicHeight, withMediaBorder},
      imageRatioId,
      imageMode,
      globals: {
        shouldShowWishlistButton,
        product,
        experiments: {renderProductPageSlots},
      },
      layout,
    } = this.props;
    const keyMedia = product.media[0];
    const layoutDimensions = dimensions.mainMedia;
    const withProductMediaNavigation = this.isWithThumbnails();

    /* istanbul ignore next: todo: test */
    if (keyMedia) {
      keyMedia.height = keyMedia.height > 0 ? keyMedia.height : this.imageDimensionFallback.height;
      keyMedia.width = keyMedia.width > 0 ? keyMedia.width : this.imageDimensionFallback.width;
    }

    if (withImageRatio) {
      const {
        ratio: {width, height},
      } = getMainImageRatio(keyMedia, imageRatioId);
      layoutDimensions.heightConf = {
        num: Math.ceil(layoutDimensions.widthConf.num * (height / width)),
        unit: 'px',
      };
    }

    const borderWidth = 1;
    if (withMediaBorder) {
      layoutDimensions.heightConf.num = layoutDimensions.heightConf.num - borderWidth * 2;
      layoutDimensions.widthConf.num = layoutDimensions.widthConf.num - borderWidth * 2;
    }

    return (
      <div data-hook="product-gallery-root" className={this.getRootClasses(withImageRatio)}>
        <ProductGalleryProvider
          withImageRatio={withImageRatio}
          imageRatioId={imageRatioId}
          media={product.media}
          key={this.signature}>
          <ProductGalleryContext.Consumer>
            {({selectedIndex}) => {
              return (
                <ModalGalleryLayout externalSelectedIndex={selectedIndex}>
                  {(modalZoom) => {
                    return (
                      <div
                        data-hook="main-media-container"
                        className={cx(this.getMainMediaContainerClass(), {
                          'modal-zoom-icon': modalZoom.isEnabled,
                        })}
                        onClick={modalZoom.open}>
                        <MainMedia
                          imageMode={imageMode}
                          swipeToScroll={swipeToScroll}
                          product={product}
                          layoutDimensions={layoutDimensions}
                          withDynamicHeight={withDynamicHeight}
                        />
                      </div>
                    );
                  }}
                </ModalGalleryLayout>
              );
            }}
          </ProductGalleryContext.Consumer>
          {withProductMediaNavigation && this.getProductMediaNavigation()}
        </ProductGalleryProvider>
        {shouldShowWishlistButton && !renderProductPageSlots && (
          <ShowOnMobileOnly>
            <WishlistButton mode={Mode.FLOATING} />
          </ShowOnMobileOnly>
        )}
        {renderProductPageSlots && layout !== Layout.Stunning && (
          <div className={style.mediaSlotsContainer}>
            <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageMedia1} />
          </div>
        )}
      </div>
    );
  }
}

export const ProductGalleryLayout = withGlobalProps(ProductGalleryLayoutComponent);
